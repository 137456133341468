import { Routes, Route } from 'react-router-dom'

import MainPage from './pages/Main';
import Links from './pages/Links';
import Briefing from './pages/Briefing';
import Confirmacao from './pages/Briefing/confirmacao';
import Proposta from './pages/Proposta';
import PolicyPrivacy from './pages/privacyPolicy';
import NotFound from './pages/NotFound';

export function AppRoutes() {

  return (

    <Routes>

      <Route path='/' element={<MainPage />} />

      <Route path='/links' element={<Links />} />

      <Route path='/briefing' element={<Briefing />} />

      <Route path='/confirmation' element={<Confirmacao />} />

      <Route path='/commercial_proposal/:idProposta' element={<Proposta />} />

      <Route path='/privacy_policy' element={<PolicyPrivacy />} />

      <Route path='/not_found' element={<NotFound />} />

      <Route path='*' element={<NotFound />} />

    </Routes>

  )

}
