import React, { useState, useEffect } from "react";

import { TextFooter } from "./styles";

import { Helmet } from "react-helmet";

const Navbar = React.lazy(() => import('../../components/Navbar'));
const Inicio = React.lazy(() => import('./Inicio'));
const DigitalSolutions = React.lazy(() => import('./solucoesDigitais'));
const CustomizeTomorrow = React.lazy(() => import('./CostumizandoAmanha'));
const FullServices = React.lazy(() => import('./ServicosCompletos'));
const MainProjects = React.lazy(() => import('./PrincipaisProjetos'));
const Profile = React.lazy(() => import('./SobreMim'));
const Contact = React.lazy(() => import('./Contato'));

import { PreLoader } from "../../components/PreLoader";

import { FaRegCopyright } from "react-icons/fa6";

export default function Main() {

  const [loading, setLoading] = useState(true);

   useEffect(() => {
    if (document.readyState === 'complete') {

      setLoading(false);

    } else {

      const handleLoad = () => {
        setLoading(false);
      };

      window.addEventListener('load', handleLoad);

      return () => {
        window.removeEventListener('load', handleLoad);
      };

    }
  }, []);

  if(loading){

    return(

      <PreLoader />

    )

  }

  return (

    <>
      <Helmet>
        <title>Noobiel Dev - Desenvolvimento Web</title>
        <meta name="description" content="Descubra soluções personalizadas de desenvolvimento web com a Noobiel Dev. Especializados em criação de sites, SEO e E-Commerce, oferecemos suporte humanizado e projetos únicos para empresas e indivíduos. Transforme suas ideias em realidade digital conosco!" />

        <meta property="og:title" content="Noobiel Dev - Desenvolvimento Web" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://noobieldev.com.br/" />
        <meta property="og:image" content="https://noobieldev.com.br/static/media/gabriel.f7a5d0bf107b18a83380.webp" />
        <meta property="og:image:url" content="https://noobieldev.com.br/static/media/gabriel.f7a5d0bf107b18a83380.webp" />
        <meta property="og:description" content="Descubra soluções personalizadas de desenvolvimento web com a Noobiel Dev. Especializados em criação de sites, SEO e E-Commerce, oferecemos suporte humanizado e projetos únicos para empresas e indivíduos. Transforme suas ideias em realidade digital conosco!" />
        <meta property="og:site_name" content="Noobiel Dev - Desenvolvimento Web" />

        <meta name="twitter:title" content="Noobiel Dev - Desenvolvimento Web" />
        <meta name="twitter:description" content="Descubra soluções personalizadas de desenvolvimento web com a Noobiel Dev. Especializados em criação de sites, SEO e E-Commerce, oferecemos suporte humanizado e projetos únicos para empresas e indivíduos. Transforme suas ideias em realidade digital conosco!" />
        <meta name="twitter:card" content="website" />
        <meta name="twitter:image" content="https://noobieldev.com.br/static/media/gabriel.f7a5d0bf107b18a83380.webp" />
        <meta name="twitter:site" content="https://noobieldev.com.br/" />

        <link rel="alternate" href="https://noobieldev.com.br/" hrefLang="pt-br" />
        <link rel="canonical" href="https://noobieldev.com.br/" />

        <meta name="keywords" content="Desenvolvimento Web, Desenvolvimento de Sites, Criação de Sites, Criação de Sites Profissionais, Criação de Landing Pages, Criação de Sites E-Commerce, Criação de Sites Institucionais, Criação de Sites de Venda, Criação de Sites de Venda Online, Criação de Sites de Venda Presencial, Criação de Sites de Venda Digital, Criação de Sites de Venda Online, Criação de Sites de Venda Presencial, Criação de Sites de Venda Digital" />

        <meta name="author" content="NOOBIEL DEV" />

        <meta name="robots" content="index, follow" />

        <meta name="language" content="Portuguese" />

        <meta name="revisit-after" content="7 days" />

      </Helmet>

      <Navbar />

        <Inicio />

      <main className='container'>

        <DigitalSolutions />

        <CustomizeTomorrow />

        <FullServices />

        <MainProjects />

        <Profile />

        <Contact />

        <footer className="row py-3">

          <section className="col-12 text-center">

            <TextFooter>
              <FaRegCopyright /> &nbsp; Todos os direitos reservados - <span>Noobiel Dev</span>
            </TextFooter>

          </section>

        </footer>

      </main>

    </>
  )

}
