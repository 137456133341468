import styled from 'styled-components';

export const TextFooter = styled.p`

  font-size: ${({theme})=>theme.fontSize.md};
  color: ${({theme})=>theme.colors.black};
  font-weight: 600;

  span{

    color: ${({theme})=>theme.colors.purple};

  }

`;
