import { useEffect } from "react";

import { Particles } from "./styles";

import 'particles.js';

declare const window: any;

export function ParticlesBg(){

  useEffect(()=>{

      window.particlesJS('particles-js', {
        "particles": {
          "number": {
              "value": 80, //REDUZIDO PARA UMA MENOR DENSIDADE
              "density": {
                  "enable": true,
                  "value_area": 800
              }
          },
          "color": {
              "value": "#493eda"
          },
          "shape": {
              "type": "circle",
              "stroke": {
                  "width": 0,
                  "color": "#252525"
              },
              "polygon": {
                  "nb_sides": 5
              }
          },
          "opacity": {
              "value": 0.5,
              "random": true, //ADICIONADO ALEATORIEDADE PARA VARIAR A OPACIDADE
              "anim": {
                  "enable": false,
              }
          },
          "size": {
              "value": 3,
              "random": true, //TAMANHO ALEATÃ“RIO PARA VARIAR O TAMANHO DAS PARTÃCULAS
              "anim": {
                  "enable": false,
              }
          },
          "line_linked": {
              "enable": true,
              "distance": 150,
              "color": "#493eda",
              "opacity": 0.4,
              "width": 1
          },
          "move": {
              "enable": true,
              "speed": 3, //VELOCIDADE REDUZIDA PARA SUAVIZAR O MOVIMENTO
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                  "enable": false
              }
          }
      },
      "interactivity": {
          "detect_on": "canvas",
          "events": {
              "onhover": {
                  "enable": true,
                  "mode": "repulse",
                  "parallax": {
                      "enable": false,
                      "force": 60,
                      "smooth": 10
                  }
              },
              "onclick": {
                  "enable": true,
                  "mode": "push"
              },
              "resize": true
          },
          "modes": {
              "grab": {
                  "distance": 140,
                  "line_linked": {
                      "opacity": 1
                  }
              },
              "bubble": {
                  "distance": 400,
                  "size": 40,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
              },
              "repulse": {
                  "distance": 50, //DIMINUÃ‡ÃƒO DO VALOR PARA REDUZIR O ESPAÃ‡O
                  "duration": 0.4
              },
              "push": {
                  "particles_nb": 4
              },
              "remove": {
                  "particles_nb": 2
              }
          }
      },
      "retina_detect": true
        });

  }, [])

  return(

    <Particles id='particles-js' ></Particles>

  )

}
