import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

  *,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html{
    overflow-x: hidden;
  }

  body{
    font-family: ${({theme})=>theme.fontFamily.raleway};
    color: ${({theme})=>theme.colors.black};
  }

  ::-webkit-scrollbar {
    width: 6px;
    transition: all .4s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({theme})=>theme.colors.purple};
    border-radius: 999px;
    width: 6px;
    transition: all .4s ease;
  }

  ::-webkit-scrollbar-track {
    background: ${({theme})=>theme.colors.white};
    width: 6px;
  }

  /* Estiliza a parte da barra de rolagem que está sendo clicada */
  ::-webkit-scrollbar-thumb:active {
    background: ${({theme})=>theme.colors.purpleHover}; /* cor do polegar ao ser clicado */
  }

  .bgWhite{
    background-color: ${({theme})=>theme.colors.white};
  }

  .bgDegrade{
    background: linear-gradient(50deg, #8D8AED 10%, #3A36BF 100%);
  }

  .purple{
    color: ${({theme})=>theme.colors.purple};
  }

  .white{
    color: ${({theme})=>theme.colors.white};
  }

  .purpleBtn{

    position: relative;
    padding: 10px 22px;
    border-radius: 999px;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: ${({theme})=>theme.colors.purple};
    transition: all 0.2s ease;
    text-decoration: none;

    &:disabled{

      background-color: #9370db;

    }

    &:hover{

      background-color: ${({theme})=>theme.colors.purple};
      color: ${({theme})=>theme.colors.white};

    }

    &:focus{
      background-color: ${({theme})=>theme.colors.purple};
      color: ${({theme})=>theme.colors.white};
      transform: scale(0.96);
    }

    &::before,
    &::after{

      content: "";
      position: absolute;
      width: 150%;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
      z-index: -1000;
      background-repeat: no-repeat;

    }

    &:hover::before{

      top: -70%;
      background-image: radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, ${({theme})=>theme.colors.purple} 20%, transparent 30%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, ${({theme})=>theme.colors.purple} 15%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%);
      background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
        10% 10%, 18% 18%;
      background-position: 50% 120%;
      animation: greentopBubbles 0.6s ease;

    }

    @keyframes greentopBubbles {
      0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
          40% 90%, 55% 90%, 70% 90%;
      }

      50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
          50% 50%, 65% 20%, 90% 30%;
      }

      100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
          50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
    }


    &:hover:after{

      bottom: -70%;
      background-image: radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, ${({theme})=>theme.colors.purple} 15%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%),
        radial-gradient(circle, ${({theme})=>theme.colors.purple} 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
      background-position: 50% 0%;
      animation: greenbottomBubbles 0.6s ease;
    }

    @keyframes greenbottomBubbles {
      0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
          70% -10%, 70% 0%;
      }

      50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
          105% 0%;
      }

      100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
          110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
    }

  }

  .purpleOutlineBtn{

    background-color: ${({theme})=>theme.colors.white};
    color: ${({theme})=>theme.colors.purple};
    border: 1px solid ${({theme})=>theme.colors.purple};
    padding: 10px 22px;
    border-radius: 999px;
    text-decoration: none;
    line-height: 1.15;

  }

  .nav-link{
    font-weight: 600;
    color: ${({theme})=>theme.colors.black};
    cursor: pointer;

    &:hover{

      color: ${({theme})=>theme.colors.purple};

    }

  }

  .form-check-input:checked {
    background-color: ${({theme})=>theme.colors.purple};
    border-color: ${({theme})=>theme.colors.purple};
  }

  .error{

    color: #FF6347;

  }

  .bgPurple{

    background-color: ${({theme})=>theme.colors.purple}

  }

  .vh-100{

    height: 100vh;

  }

  .loader{

    width: 60px;
    height: 60px;

    &::before{

      content: "";
      box-sizing: border-box;
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border-top: 2px solid ${({theme})=>theme.colors.purple};
      border-right: 2px solid transparent;
      animation: spinner 0.8s linear infinite;

    }

    @keyframes spinner {

      to {
        transform: rotate(360deg);
      }

    }

  }

  .inputForm{

  width: 100%;
  height: 40px;
  line-height: 30px;
  padding: .375rem .75rem;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  outline: none;
  background-color: ${({theme})=>theme.colors.white};
  color: ${({theme})=>theme.colors.black};
  transition: .5s ease;

  &::placeholder{

    color: #94a3b8;

  }

  &:focus,
  &:hover{

  outline: none;
  border-color: rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 3px rgb(129 140 248 / 30%);

  }

  }

`
