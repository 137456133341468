import styled from "styled-components";

export const Particles = styled.article`

  width: 100%;
  height: 100vh;
  background: ${({theme})=>theme.colors.white};
  position: absolute;
  z-index: -1;

`;
